exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-fundusze-eu-jsx": () => import("./../../../src/pages/fundusze-eu.jsx" /* webpackChunkName: "component---src-pages-fundusze-eu-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-o-firmie-jsx": () => import("./../../../src/pages/o-firmie.jsx" /* webpackChunkName: "component---src-pages-o-firmie-jsx" */),
  "component---src-pages-oferta-jsx": () => import("./../../../src/pages/oferta.jsx" /* webpackChunkName: "component---src-pages-oferta-jsx" */),
  "component---src-pages-polityka-prywatnosci-jsx": () => import("./../../../src/pages/polityka-prywatnosci.jsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-jsx" */),
  "component---src-pages-realizacje-jsx": () => import("./../../../src/pages/realizacje.jsx" /* webpackChunkName: "component---src-pages-realizacje-jsx" */),
  "component---src-pages-system-mebli-modulowych-jsx": () => import("./../../../src/pages/system-mebli-modulowych.jsx" /* webpackChunkName: "component---src-pages-system-mebli-modulowych-jsx" */),
  "component---src-pages-uslugi-jsx": () => import("./../../../src/pages/uslugi.jsx" /* webpackChunkName: "component---src-pages-uslugi-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */),
  "component---src-templates-products-jsx": () => import("./../../../src/templates/products.jsx" /* webpackChunkName: "component---src-templates-products-jsx" */)
}

